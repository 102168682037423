import http from 'common/http'
import {
  clearFeatures,
  fieldFetched,
  fieldsFetched,
  harvestedFieldsFetched,
  featuresFetched,
  featuresFetchedA,
  featuresFetchedB,
  editFieldDetails,
  removeField,
  setDefaultExtent,
  setFeaturesOverview,
  layerInfoFetched,
  layerInfoAFetched,
  layerInfoBFetched,
  fieldsHarvestedAFetched,
  fieldsHarvestedBFetched,
  fieldsImported,
  downloadingLayer,
  updateFeaturesOverview,
  cancelEditFieldName,
} from 'actions/field'
import { featuresForClientFetched } from 'actions/client'
import { appReady, addEvent, closeModal, showModal } from 'actions/app'
import { stopDrawingBoundary, pointInfoRes, areaInfoRes } from 'actions/map'
import { isEmpty, chunk } from 'lodash-es'
import Alert from '@mui/material/Alert'
import i18next from 'i18next'
import 'regenerator-runtime/runtime'

export const createField = (data) => {
  return (dispatch) => {
    return http.post('/field', data).then((res) => {
      dispatch(fieldFetched(res.data))
      dispatch(appReady())
      dispatch(addEvent('Field created message', 'Field created title'))
      dispatch(closeModal())
      dispatch(fetchFields())
    })
  }
}

export const createMultipleFields = (dataArray) => {
  return (dispatch) => {
    const chunkSize = 25
    const chunks = chunk(dataArray, chunkSize)
    return Promise.all(
      chunks.map((item) => http.post('/field/field-import/multiple', item)),
    )
      .then((results) => {
        dispatch(fieldsFetched(results[results.length - 1].data))
        dispatch(addEvent('fields_create_success', 'fields_created'))
        dispatch(closeModal())
        return {
          data: results[results.length - 1].data,
          message: 'Fields created',
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          alert(i18next.t('failed_to_save_fields'))
        } else {
          alert(i18next.t('invalid_field_data'))
        }
      })
  }
}

export const editField = (idField, data) => {
  return (dispatch) => {
    return http.put(`/field/${idField}`, data).then((res) => {
      dispatch(editFieldDetails(res.data))
      dispatch(fieldFetched(res.data))
      dispatch(cancelEditFieldName())
      dispatch(closeModal())
    })
  }
}

export const editMultipleField = (idField, data) => {
  return (dispatch) => {
    return http.put(`/field/update-fields`, data).then((res) => {
      dispatch(editFieldDetails(res.data))
      dispatch(fieldFetched(res.data))
      dispatch(cancelEditFieldName())
    })
  }
}

export const deleteField = (idField) => {
  return (dispatch) => {
    return http.delete(`/field/${idField}`).then(() => {
      dispatch(removeField(idField))
      dispatch(closeModal())
      dispatch(fetchBBox())
    })
  }
}

export const fetchFields = () => {
  return (dispatch) => {
    return http.get('/field').then((res) => {
      dispatch(fieldsFetched(res.data))
    })
  }
}

export const fetchDeletedFields = () => {
  return http
    .get('/field?includeDeleted=only&includeSvgs=false')
    .then((res) => res.data)
}

export const saveMultipleFeatures = (payload) => {
  return (dispatch) => {
    return http
      .post('/field/multiple-features', payload)
      .then((res) => {
        dispatch(stopDrawingBoundary())
        dispatch(featuresFetched(res.data))
        dispatch(updateFeaturesOverview(res.data))
      })
      .catch(() => {
        // let ids = payload.map(value => value['id'])
        // dispatch(deleteFields(ids))
      })
  }
}

export const saveFeatures = (idField, geojson, disableDraw = true) => {
  return (dispatch) => {
    return http
      .post(`/field/${idField}/features`, geojson)
      .then((res) => {
        if (disableDraw) dispatch(stopDrawingBoundary())
        dispatch(featuresFetched(res.data))
        dispatch(updateFeaturesOverview(res.data))
        dispatch(addEvent('Boundary saved'))
      })
      .catch(() => {
        dispatch(removeField(idField))
      })
  }
}

export const editFeatures = (idField, geojson, harvestId) => {
  return (dispatch) => {
    return http
      .put(`/field/${idField}/features`, { geojson, harvest_id: harvestId })
      .then((res) => {
        dispatch(stopDrawingBoundary())
        dispatch(featuresFetched(res.data))
        dispatch(updateFeaturesOverview(res.data))
        dispatch(addEvent('Boundary saved'))
      })
      .catch(() => {
        dispatch(removeField(idField))
      })
  }
}

export const fetchFeatures = (idField) => {
  return (dispatch) => {
    return http.get(`/field/${idField}/features`).then((res) => {
      dispatch(featuresFetched(res.data))
    })
  }
}

export const fetchFeaturesA = (idField) => {
  return (dispatch) => {
    return http.get(`/field/${idField}/features`).then((res) => {
      dispatch(featuresFetchedA(res.data))
    })
  }
}

export const fetchFeaturesB = (idField) => {
  return (dispatch) => {
    return http.get(`/field/${idField}/features`).then((res) => {
      dispatch(featuresFetchedB(res.data))
    })
  }
}

export const removeFeatures = (idField) => {
  return (dispatch) => {
    return http.delete(`/field/${idField}/features`).then((res) => {
      if (res.data.deleted !== 0) {
        dispatch(clearFeatures())
        dispatch(featuresFetched())
        dispatch(addEvent('Boundary removed'))
      }
    })
  }
}

export const fetchBBox = (clientId) => {
  return (dispatch) => {
    let queryParams = ''
    if (clientId) {
      queryParams = `?clientId=${clientId}`
    }
    return http.get(`/field/bbox${queryParams}`).then((res) => {
      const { extent, features } = res.data

      dispatch(setDefaultExtent(extent))
      dispatch(setFeaturesOverview(features))
      if (clientId && features) {
        dispatch(
          featuresForClientFetched({
            bbox: extent,
            clientId: clientId,
          }),
        )
      }
    })
  }
}

export const fetchFilteredFields = (year, crop, clientId, farmId) => {
  return (dispatch) => {
    let url = ''
    if (farmId) {
      url = `/field/filter?year=${year}&crop=${crop}&farmId=[${farmId}]`
    } else if (clientId) {
      url = `/field/filter?year=${year}&crop=${crop}&clientId=${clientId}`
    } else {
      url = `/field/filter?year=${year}&crop=${crop}`
    }
    return http.get(url).then((res) => {
      dispatch(harvestedFieldsFetched(res.data))
    })
  }
}

export const filterFieldsCFFA = (year, crop, clientId, farmIds) => {
  return (dispatch) => {
    let url = `/field/filter?year=${year}&crop=${crop}`
    if (farmIds && farmIds.length) {
      url += `&farmId=[${farmIds}]`
    } else if (clientId) {
      url += `&clientId=${clientId}`
    }

    return http.get(url).then((res) => {
      dispatch(fieldsHarvestedAFetched(res.data))
    })
  }
}

export const filterFieldsCFFB = (year, crop, clientId, farmIds) => {
  return (dispatch) => {
    let url = `/field/filter?year=${year}&crop=${crop}`
    if (farmIds && farmIds.length) {
      url += `&farmId=[${farmIds}]`
    } else if (clientId) {
      url += `&clientId=${clientId}`
    }

    return http.get(url).then((res) => {
      dispatch(fieldsHarvestedBFetched(res.data))
    })
  }
}

export const getLayerInfoByHarvest = (id, year) => {
  return (dispatch) => {
    if (id && year) {
      const url = `/layer/info?harvest_id=${id}&year=${year}`
      return http.get(url).then((res) => {
        dispatch(layerInfoFetched(res.data))
      })
    }
  }
}

export const getLayerInfoAByHarvest = (id, year) => {
  return (dispatch) => {
    if (id && year) {
      const url = `/layer/info?harvest_id=${id.toString()}&year=${year}`
      return http.get(url).then((res) => {
        dispatch(layerInfoAFetched(res.data))
      })
    }
  }
}

export const getLayerInfoBByHarvest = (id, year) => {
  return (dispatch) => {
    if (id && year) {
      const url = `/layer/info?harvest_id=${id}&year=${year}`
      return http.get(url).then((res) => {
        dispatch(layerInfoBFetched(res.data))
      })
    }
  }
}

export const findFields = (name) => {
  return (dispatch) => {
    return http.get('/field/find/' + name).then((res) => {
      dispatch(fieldsFetched(res.data))
    })
  }
}

export const fetchFieldImport = (fileObj) => {
  return (dispatch) => {
    return http.post('/field/import', fileObj).then((res) => {
      dispatch(fieldsImported(res.data))
    })
  }
}

export const fetchPointInfo = (url) => {
  return (dispatch) => {
    return fetch(url, {
      method: 'GET',
      mode: 'cors',
    }).then((res) => {
      res.text().then((result) => {
        const data = JSON.parse(result)
        if (isEmpty(data.features)) {
          dispatch(pointInfoRes(undefined))
        } else {
          const { properties } = data.features[0]
          dispatch(pointInfoRes(properties))
        }
      })
    })
  }
}

export const fetchAreaInfo = (url) => {
  return (dispatch) => {
    return fetch(url, {
      method: 'GET',
      mode: 'cors',
    }).then((res) => {
      res.text().then((result) => {
        const data = JSON.parse(result)
        if (isEmpty(data.features)) {
          dispatch(areaInfoRes(undefined))
        } else {
          dispatch(areaInfoRes(data))
        }
      })
    })
  }
}

export const exportLayer = (id, data) => {
  return (dispatch) => {
    downloadExport(id, data, dispatch)
  }
}

//Run function asynchronously so that some browsers (like Safari) don't kill the request
//if it doesn't return soon enough. Safari is 10 seconds for Synchronous requests which
//export used to be.
async function downloadExport(id, data, dispatch) {
  try {
    const result = await http.post(`/harvest/${id}/export`, data)
    if (result?.data?.url && result.data.url !== '') {
      const parts = result.data.url.split('/')
      dispatch(
        downloadingLayer({
          url: result.data.url,
          fileName: parts[parts.length - 1],
        }),
      )
    } else {
      dispatch(
        showModal(
          <Alert severity="error">{i18next.t('layer_unavailable')}</Alert>,
          { title: i18next.t('alert') },
        ),
      )
    }
  } catch (e) {
    dispatch(
      showModal(
        <Alert severity="error">{i18next.t('layer_download_error')}</Alert>,
        { title: i18next.t('alert') },
      ),
    )
  }
}
