import { Box, FormControlLabel, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { getLayerInfoByHarvest } from 'api/field'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import { useReactToPrint } from 'react-to-print'
import PrintIcon from '@mui/icons-material/Print'
import { convertYieldToPref, convertAreaToPref } from 'common/unitConvert'
import { useEffect } from 'react'
import { isEmpty } from 'lodash-es'
import { useLegacySLD, getTranslatedName } from 'common/misc'
import { transform } from 'ol/proj'

const MAX_HEIGHT = 450
const MAX_WIDTH = 600

const useStyles = makeStyles(() => ({
  borderedBox: {
    border: '5px solid #003057',
    overflowY: 'scroll',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '1em',
    maxWidth: '100%',
  },
  '@global': {
    '.MuiFormControlLabel-label': {
      fontWeight: 500,
      marginRight: '5px',
    },
  },
  mapDiv: {
    minHeight: '300px',
    maxHeight: `${MAX_HEIGHT + 2}px`,
    border: '1px solid #003057',
    borderRadius: '5px',
    textAlign: 'center',
  },
  mapImg: {},
  harvestDataDiv: {},
  icons: {
    '& path': {
      fill: '#FFF',
    },
  },
}))

export default function HarvestReport(props) {
  function calculateHeightAndWidth(extentMin, extentMax) {
    const x = Math.abs(extentMax[0] - extentMin[0])
    const y = Math.abs(extentMax[1] - extentMin[1])

    let tmpHeight = Math.floor((y / x) * MAX_WIDTH)
    if (tmpHeight > MAX_HEIGHT) {
      setHeight(MAX_HEIGHT)
      setWidth(Math.floor((x / y) * MAX_HEIGHT))
    } else {
      setHeight(tmpHeight)
      setWidth(MAX_WIDTH)
    }
  }

  const classes = useStyles()
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const layerInfo = useSelector((state) => state.field['layerInfo'])
  const hardware = useSelector((state) => state.hardware.collection)
  const equipment = useSelector((state) => state.equipment.collection)
  const userPrefs = useSelector((state) => state.account.singleton.prefs)

  const [url, setMapUrl] = useState('')

  const [legend, setLegend] = useState('')
  const [height, setHeight] = useState(450)
  const [width, setWidth] = useState(450)
  const [bbox, setBbox] = useState([])
  const [legendSLD, setLegendSLD] = useState('')
  const [cqlFilter, setCqlFilter] = useState('')

  const [equipmentInfo, setEquipmentInfo] = useState([])

  const componentRef = useRef()
  const selectedHarvest = props.harvestObj
  const selectedLayerObj = props.layerName

  useEffect(() => {
    let harvesterInfo = selectedHarvest?.stats?.harvester_info
    let finalArray = []
    if (harvesterInfo) {
      //will work if harvesterInfo is either an array or map (seems the map is keyed by ymId or something that looks like it)
      for (let harvester in harvesterInfo) {
        const h = harvesterInfo[harvester]
        //if harvesterInfo has a ymId use it, if it doesn't then try to use whatever harvester info is indexed by.
        const ymId = h?.ymId || harvester
        let hwObj = hardware.find((hw) => hw?.farmtrxHardwareId === ymId)
        let eqObj =
          hwObj && equipment.find((eq) => eq.id === hwObj?.equipmentId)
        finalArray.push({
          eqNickname: eqObj ? eqObj.nickname : ymId,
          totalYieldG: h['total_yield_g'],
          ymId: ymId,
          perCombineHarvest: h['harvested_area_m2'],
        })
      }
    }
    setEquipmentInfo(finalArray)
  }, [selectedHarvest])

  useEffect(() => {
    //get map
    if (selectedHarvest) {
      let layerDetails = {}
      let legacySld = false
      if (selectedHarvest?.stats?.lp_version) {
        legacySld = useLegacySLD(selectedHarvest?.stats?.lp_version)
      }
      setCqlFilter(encodeURI(`harvest_id='${selectedHarvest.id}'`))
      if (layerInfo && layerInfo.length) {
        layerDetails = layerInfo.find(
          (l) =>
            l.harvestId === selectedHarvest.id &&
            l.layerName === selectedLayerObj.key,
        )
        if (layerDetails && Object.keys(layerDetails).length) {
          let legendUrl = layerDetails.legendUrl
          if (
            legendUrl !== '' &&
            legacySld &&
            !(
              userPrefs?.units?.yieldunit_in == 'bu_ac' ||
              userPrefs?.units?.yieldunit_in == 'lbs_acre'
            )
          ) {
            legendUrl = layerDetails.legendUrl.replace('.sld', '_hct.sld')
          }

          setLegendSLD(legendUrl)

          const extentMin = [layerDetails.bboxMinx, layerDetails.bboxMiny]
          const extentMax = [layerDetails.bboxMaxx, layerDetails.bboxMaxy]
          const transformedExtentMin = transform(
            extentMin,
            'EPSG:4326',
            'EPSG:3857',
          )
          const transformedExtentMax = transform(
            extentMax,
            'EPSG:4326',
            'EPSG:3857',
          )
          setBbox([
            transformedExtentMin[0],
            transformedExtentMin[1],
            transformedExtentMax[0],
            transformedExtentMax[1],
          ])

          calculateHeightAndWidth(transformedExtentMin, transformedExtentMax)
        } else {
          dispatch(
            getLayerInfoByHarvest(selectedHarvest.id, selectedHarvest.cropYear),
          )
        }
      } else {
        dispatch(
          getLayerInfoByHarvest(selectedHarvest.id, selectedHarvest.cropYear),
        )
      }
    }
  }, [layerInfo, selectedHarvest, selectedLayerObj])

  useEffect(() => {
    if (bbox.length > 0 && Object.keys(selectedLayerObj).length) {
      setLegend(
        `${GEOSERVER_URL}/ows?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&SCALE=0&LAYER=${selectedLayerObj.key}&SLD=${legendSLD}`,
      )
      setMapUrl(
        `${GEOSERVER_URL}/ows?service=WMS&version=1.3.0&request=GetMap&bbox=${bbox}&height=${height}&width=${width}&SLD=${legendSLD}&crs=EPSG:3857&CQL_FILTER=${cqlFilter}&styles=&format=image%2Fpng`,
      )
    }
  }, [height, width, bbox, legendSLD, cqlFilter, selectedLayerObj])

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${selectedHarvest['fieldName']} - ${selectedHarvest['cropName']}`,
  })

  return isEmpty(selectedHarvest) && isEmpty(selectedLayerObj) ? (
    <Box
      sx={{
        display: 'flex',
        m: 2,
        p: 2,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <b>{t('click_generate_view_report')}</b>
    </Box>
  ) : (
    <Box sx={{ display: 'flex' }} width={1}>
      <Container fixed className={classes.container}>
        <Box my={2}>
          <Button
            variant="contained"
            startIcon={<PrintIcon className={classes.icons} />}
            onClick={handlePrint}
          >
            {t('print_report')}
          </Button>
        </Box>
        <Box className={classes.borderedBox} ref={componentRef}>
          <h2 style={{ marginLeft: '16px' }}>
            {`${t('harvest_report')}`.toUpperCase()}
          </h2>
          <>
            <Box mx={2} my={2}>
              <Grid container spacing={{ xs: 0.5, md: 0.5 }}>
                <Grid item xs={5} md={5}>
                  <FormControlLabel
                    control={<span>{selectedHarvest?.stats?.field_name}</span>}
                    label={`${t('field_name')}:`}
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item xs={7} md={7}></Grid>
                <Grid item xs={5} md={5}>
                  <FormControlLabel
                    control={
                      <span>
                        {selectedHarvest &&
                          Object.keys(selectedHarvest).length &&
                          getTranslatedName(
                            selectedHarvest?.crop?.cropClassification,
                          )}
                      </span>
                    }
                    label={`${t('crop_type')}:`}
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item xs={2} md={2}></Grid>
                <Grid item xs={5} md={5}>
                  <FormControlLabel
                    control={<span>{selectedHarvest['cropYear']}</span>}
                    label={`${t('harvest_year')}:`}
                    labelPlacement="start"
                  />
                </Grid>
              </Grid>
            </Box>

            <Box mx={2} my={2} className={classes.mapDiv}>
              <Grid container spacing={0.5} paddingTop="5px">
                <Grid item xs={9}>
                  <img
                    key={selectedLayerObj}
                    src={url}
                    alt="map"
                    style={{
                      maxWidth: '600px',
                      maxHeight: `${MAX_HEIGHT}px`,
                      objectFit: 'contain',
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <img
                    key={selectedLayerObj + '_legend'}
                    src={legend}
                    alt="legend"
                    style={{
                      width: '150px',
                      maxHeight: `${MAX_HEIGHT}px`,
                      objectFit: 'contain',
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mx={2} my={2} className={classes.harvestDataDiv}>
              <Typography variant="h6">{`${t(
                'agronomic_layer_data',
              )}`}</Typography>
              <hr />
              <Grid container spacing={{ xs: 0.5, md: 0.5 }}>
                <Grid item xs={5} md={5}>
                  <FormControlLabel
                    control={
                      <span>
                        {selectedHarvest &&
                          Object.keys(selectedHarvest).length &&
                          getTranslatedName(selectedHarvest.cropName)}
                      </span>
                    }
                    label={`${t('crop')}:`}
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item xs={2} md={2}></Grid>
                <Grid item xs={5} md={5}>
                  <FormControlLabel
                    control={
                      <span>
                        {selectedHarvest &&
                          Object.keys(selectedHarvest).length &&
                          `${convertYieldToPref(
                            selectedHarvest?.stats?.total_yield_g,
                            userPrefs['units']['totalyield_in'],
                            selectedHarvest?.crop?.buPerTonne,
                          ).toFixed(2)} ${t(
                            userPrefs['units']['totalyield_in'],
                          )}`}
                      </span>
                    }
                    label={`${t('total_yield')}:`}
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item xs={5}>
                  <FormControlLabel
                    control={
                      <span>
                        {selectedHarvest.stats?.avg_moist
                          ? `${selectedHarvest.stats?.avg_moist?.toFixed(2)} %`
                          : t('n_a')}
                      </span>
                    }
                    label={`${t('harvest_moisture')}:`}
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item xs={2} md={2}></Grid>
                <Grid item xs={5}>
                  <FormControlLabel
                    control={
                      <span>
                        {selectedHarvest &&
                          Object.keys(selectedHarvest).length &&
                          `${convertYieldToPref(
                            selectedHarvest?.stats?.avg_harvested_yield_g_m2,
                            userPrefs['units']['yieldunit_in'],
                            selectedHarvest?.crop?.buPerTonne,
                          ).toFixed(2)} ${t(
                            userPrefs['units']['yieldunit_in'],
                          )}`}
                      </span>
                    }
                    label={`${t('harvested_average_yield')}:`}
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item xs={5}>
                  <FormControlLabel
                    control={
                      <span>
                        {selectedHarvest &&
                          Object.keys(selectedHarvest).length &&
                          `${convertAreaToPref(
                            selectedHarvest?.stats?.harvested_area_m2,
                            userPrefs['units']['area_in'],
                          ).toFixed(2)} ${t(userPrefs['units']['area_in'])}`}
                      </span>
                    }
                    label={`${t('harvested_area')}:`}
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item xs={2} md={2}></Grid>
                <Grid item xs={5}>
                  <FormControlLabel
                    control={<span>{selectedLayerObj['name']}</span>}
                    label={`${t('map_type')}:`}
                    labelPlacement="start"
                  />
                </Grid>
              </Grid>
            </Box>

            <Box mx={2} my={2} className={classes.harvestDataDiv}>
              <Box>
                <Typography variant="h6">{`${t('field')} / ${t(
                  'equipment',
                )} ${t('data')}`}</Typography>
                <hr />
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('name')}</TableCell>
                        <TableCell align="right">
                          {t('total_per_combine')}
                        </TableCell>
                        <TableCell align="right">
                          {t('harvested_area_per_combine')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {equipmentInfo.map((row) => (
                        <TableRow
                          key={row.ymId}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.eqNickname}
                          </TableCell>
                          <TableCell align="right">{`${convertYieldToPref(
                            row.totalYieldG,
                            userPrefs['units']['totalyield_in'],
                            selectedHarvest?.crop?.buPerTonne,
                          ).toFixed(2)} ${t(
                            userPrefs['units']['totalyield_in'],
                          )}`}</TableCell>
                          <TableCell align="right">{`${convertAreaToPref(
                            row.perCombineHarvest,
                            userPrefs['units']['area_in'],
                          ).toFixed(2)} ${t(
                            userPrefs['units']['area_in'],
                          )}`}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </>
        </Box>
      </Container>
    </Box>
  )
}

HarvestReport.displayName = 'HarvestReport'
