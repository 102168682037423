import { Menu, MenuItem, MenuList, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { exportLayer } from 'api/field'
import { useDispatch, useSelector } from 'react-redux'
import AwaitingLayerDownload from 'components/layer-download/awaiting-download'
import { getTranslatedName } from 'common/misc'

//Icons
import {
  FarmtrxIconEditCropCalibration,
  FarmtrxIconUpdateTotalYield as EditTotalYield,
  FarmtrxIconEditTimeDelay as EditTimeDelay,
  FarmtrxIconUpdateTestWeight as UpdateTestWeight,
  FarmtrxIconEditSensorCalibration as EditSensorCalibration,
  FarmtrxIconHeader,
  FarmtrxIconRefresh,
  FarmtrxIconExportHarvest as CloudDownloadIcon,
  FarmtrxIconCrop,
  FarmtrxIconMinus,
} from '@troo/farmtrx-icons'

//Types
import { HarvestModel } from 'state/harvest'
import { HarvestPermissions } from './harvest-panel'
import { AppState } from 'state'
import { FieldModel } from 'state/field'
import { showModal } from 'actions/app'
import ChangeHarvestYear from 'components/change-harvest-year'

export type HarvestMenuProps = {
  anchorEl: null | HTMLElement
  permissions: HarvestPermissions
  onClickHandler: Function
  open: boolean
  harvest: HarvestModel
  showExportModal: Function
  onClose: Function
}
const iconSize = 16

const useStyles = makeStyles((theme) => ({
  icon: { margin: 4, height: iconSize, width: iconSize },
  text: { fontSize: '.75rem' },
}))
export default function HarvestSettingsPanel(props: HarvestMenuProps) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()
  const field = useSelector((state: AppState) => state.field.singleton)
  const fields: FieldModel[] = useSelector(
    (state: AppState) => state.field.collection,
  )

  const handleOnClick = useCallback(
    (name) => {
      if (name !== 'export_harvest') {
        props.onClickHandler(name, props.harvest)
      } else {
        props.showExportModal(props.harvest)
      }
      props.onClose()
    },
    [props.harvest],
  )

  const onDownloadUbj = useCallback(() => {
    let fieldForHarvest: FieldModel | null | undefined = null
    if (field?.id === props.harvest.fieldId) {
      fieldForHarvest = field
    } else {
      fieldForHarvest = fields.find((f) => {
        return f.id === props.harvest.fieldId
      })
    }
    if (fieldForHarvest) {
      const data = {
        tenant_id: props.harvest.tenantId,
        export_type: 'ubj',
        field_name: fieldForHarvest.name,
        year: props.harvest.cropYear,
      }
      dispatch(exportLayer(props.harvest.id, data))
      props.onClose()
      dispatch(
        showModal(<AwaitingLayerDownload />, {
          title: `Downloading UBJ: ${
            props.harvest.cropYear
          }-${getTranslatedName(props.harvest.crop.name)}`,
        }),
      )
    }
  }, [field])

  return (
    <Menu
      anchorEl={props.anchorEl}
      open={props.open}
      anchorOrigin={{ vertical: -12, horizontal: 59 }}
      id="harvest-settings"
      MenuListProps={{ 'aria-labelledby': 'harvest-settings-button' }}
      onClose={() => props.onClose(null)}
      disableEnforceFocus={true}
      closeAfterTransition={false}
    >
      <MenuList style={{ outline: 0 }}>
        {props.permissions.setHeader && (
          <MenuItem
            id="set_header"
            onClick={() => {
              handleOnClick('set_header')
            }}
          >
            <FarmtrxIconHeader className={classes.icon} />
            <Typography className={classes.text}>
              {t('set_header').toUpperCase()}
            </Typography>
          </MenuItem>
        )}
        {props.permissions.editCropType && (
          <MenuItem
            id="edit_crop_type"
            onClick={(e) => handleOnClick('edit_crop_type')}
          >
            <FarmtrxIconCrop className={classes.icon} />
            <Typography className={classes.text}>
              {t('edit_crop_type').toUpperCase()}
            </Typography>
          </MenuItem>
        )}
        {props.permissions.updateTotalYield && (
          <MenuItem id="change_ty" onClick={(e) => handleOnClick('change_ty')}>
            <EditTotalYield className={classes.icon} />
            <Typography className={classes.text}>
              {t('change_ty').toUpperCase()}
            </Typography>
          </MenuItem>
        )}
        {props.permissions.updateTestWeight && (
          <MenuItem id="change_tw" onClick={() => handleOnClick('change_tw')}>
            <UpdateTestWeight className={classes.icon} />
            <Typography className={classes.text}>
              {t('change_tw').toUpperCase()}
            </Typography>
          </MenuItem>
        )}
        {props.permissions.editSensorCalibration && (
          <MenuItem
            id="edit_sensor_calibration"
            onClick={(e) => handleOnClick('edit_sensor_calibration')}
          >
            <EditSensorCalibration className={classes.icon} />
            <Typography style={{ fontSize: '.75rem' }}>
              {t('edit_sensor_calibration').toUpperCase()}
            </Typography>
          </MenuItem>
        )}
        {props.permissions.editCropCalibration && (
          <MenuItem
            id="edit_crop_calibration"
            onClick={() => handleOnClick('edit_crop_calibration')}
          >
            <FarmtrxIconEditCropCalibration className={classes.icon} />
            <Typography className={classes.text}>
              {t('edit_crop_calibration').toUpperCase()}
            </Typography>
          </MenuItem>
        )}
        {props.permissions.exportHarvest && (
          <MenuItem
            id="export_harvest"
            onClick={() => handleOnClick('export_harvest')}
          >
            <CloudDownloadIcon color="primary" className={classes.icon} />
            <Typography className={classes.text}>
              {t('export_harvest').toUpperCase()}
            </Typography>
          </MenuItem>
        )}
        {props.permissions.editTimeDelay && (
          <MenuItem
            id="edit_time_delay"
            onClick={(e) => handleOnClick('edit_time_delay')}
          >
            <EditTimeDelay className={classes.icon} />
            <Typography className={classes.text}>
              {t('edit_time_delay').toUpperCase()}
            </Typography>
          </MenuItem>
        )}
        {props.permissions.refreshHarvest && (
          <MenuItem id="refresh" onClick={() => handleOnClick('refresh')}>
            <FarmtrxIconRefresh className={classes.icon} />
            <Typography className={classes.text}>
              {t('refresh').toUpperCase()}
            </Typography>
          </MenuItem>
        )}
        {props.permissions.downloadUbj && (
          <MenuItem id="download_ubj" onClick={() => onDownloadUbj()}>
            <CloudDownloadIcon color="primary" className={classes.icon} />
            <Typography className={classes.text}>{'DOWNLOAD UBJS'}</Typography>
          </MenuItem>
        )}
        {props.permissions.changeHarvestYear && (
          <MenuItem
            id="change_harvest_year"
            onClick={(e) => handleOnClick('change_harvest_year')}
          >
            <FarmtrxIconMinus className={classes.icon} />
            <Typography className={classes.text}>
              {t('change_harvest_year').toUpperCase()}
            </Typography>
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  )
}
