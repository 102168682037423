import { Box } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import FieldImportGrid from 'components/field-import'
import FileUploadInput from 'components/lands/upload-boundaries/fileUploadInput'
import { useSelector } from 'react-redux'
import { AppState } from 'state'
import { getClientFarm } from 'common/misc'

const useStyles = makeStyles(() => ({
  fileImportComponent: {
    p: 2,
    my: 1,
    border: '1px dashed grey',
    height: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex: '99',
  },
  uploadNew: {
    '& path': {
      fill: '#FFF',
    },
  },
  infoIcon: {
    '& path': {
      fill: '#439313',
    },
  },
}))

export default function UploadBoundariesComponent(props) {
  const { t } = useTranslation()
  const { onImportField, isTransferringData, setTransferringData } = props
  const classes = useStyles()

  const clients = useSelector(
    (state: AppState) => state.client.collection || [],
  )

  const cffEnabled = useSelector(
    (state: AppState) => state.account.singleton.prefs?.cff?.enabled ?? false,
  )
  const prefDefault = useSelector(
    (state: AppState) => state.account.singleton.prefs?.cff?.client ?? '',
  )

  const prefDefaultFarm = useSelector(
    (state: AppState) => state.account.singleton.prefs?.cff?.farm,
  )
  const { client, farms } = getClientFarm(cffEnabled, clients, prefDefault)

  return (
    <Box sx={{ p: 3, width: '85%' }}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          sx={{ boxShadow: 'none', background: '#fff' }}
        >
          <Toolbar sx={{ padding: '0 !important' }}>
            <h2 style={{ flexGrow: 1, color: 'black' }}>
              {t('upload_boundaries')}
            </h2>
            <Typography
              variant="h5"
              sx={{
                padding: 1,
                fontWeight: 500,
                fontSize: 'medium',
                color: '#439313',
              }}
            >
              <IconButton
                size="small"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 1, ml: 1 }}
              >
                <InfoOutlinedIcon className={classes.infoIcon} />
              </IconButton>
              {t('valid_fields_polygons_import')}
              <IconButton
                size="small"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 1, ml: 1 }}
              >
                <InfoOutlinedIcon className={classes.infoIcon} />
              </IconButton>
              {t('create_client_farm')}
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <FieldImportGrid
        onImportField={onImportField}
        defaultClient={client}
        defaultFarm={farms[0]}
        clients={clients}
        fileUpload={FileUploadInput}
        isTransferringData={isTransferringData}
        setTransferringData={setTransferringData}
      />
    </Box>
  )
}
