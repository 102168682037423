import * as c from 'common/c'

export const clientCreated = (data) => {
  return {
    type: c.CLIENT_CREATED,
    payload: data,
  }
}
export const clientsFetched = (data) => {
  return {
    type: c.CLIENTS_FETCHED,
    payload: data,
  }
}

export const clientEdited = (data) => {
  return {
    type: c.CLIENT_EDITED,
    payload: data,
  }
}

export const clientDeleted = (id) => {
  return {
    type: c.CLIENT_DELETED,
    payload: id,
  }
}

export const assignSelectedClient = (client) => {
  return {
    type: c.CLIENT_SELECTED,
    payload: client,
  }
}

export const farmReferenceAdded = (data) => {
  return {
    type: c.FARM_REFERENCE_ADDED,
    payload: data,
  }
}

export const farmReferenceUpdated = (data, clientId) => {
  return {
    type: c.FARM_REFERENCE_UPDATED,
    payload: { data, clientId },
  }
}

export const farmReferencesUpdated = (data) => {
  return {
    type: c.FARM_REFERENCES_UPDATED,
    payload: data,
  }
}

export const farmReferenceDeleted = (id, clientId) => {
  return {
    type: c.FARM_REFERENCE_DELETED,
    payload: { id, clientId },
  }
}

export const featuresForClientFetched = (clientId) => {
  return {
    type: c.FEATURES_FOR_CLIENT_FETCHED,
    payload: clientId,
  }
}
