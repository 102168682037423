import http from 'common/http'
import {
  closeModal,
  addEvent,
  shouldShowFarmSetupWizard,
  accountDisabled,
} from 'actions/app'
import {
  optionsFetched,
  tenantFetched,
  setEditTenant,
  setLocalTenantDetails,
  accessibleAccountsFetched,
  invitedUsersFetched,
} from 'actions/account'
import { getCropVarieties } from 'api/crop-variety'
import { getPartnerContactInfo } from 'api/user'
import { getClientFarm } from 'common/misc'
import { assignSelectedClient } from 'actions/client'
import { assignSelectedFarms } from 'actions/farm'
import { fetchBBox } from 'api/field'
import { get } from 'lodash-es'
import store from 'store'

export const fetchTenant = () => {
  return (dispatch) => {
    return http
      .get('/tenant')
      .then((res) => {
        if (
          res.data?.accountEnabled === false &&
          !localStorage.getItem('impersonate')
        ) {
          dispatch(getPartnerContactInfo())
          dispatch(accountDisabled())
        }
        dispatch(tenantFetched(res.data))
        let clientId = undefined
        let prefData = res.data?.prefs
        if (prefData?.cffEnabled && prefData?.cff?.client) {
          clientId = prefData?.cff?.client
        }

        store.dispatch(fetchBBox(clientId))

        const clients = get(store.getState(), 'client.collection', [])
        const cffPrefDefault = res.data?.prefs?.cff
        if (clients.length) {
          //@ts-ignore
          const { client, selectedFarms } = getClientFarm(
            cffPrefDefault?.enabled,
            clients,
            cffPrefDefault?.client,
          )
          dispatch(assignSelectedClient(client))

          dispatch(
            assignSelectedFarms(selectedFarms?.length ? selectedFarms : []),
          )
        }
        dispatch(getCropVarieties())
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          dispatch(shouldShowFarmSetupWizard())
          return 'TenantEmpty'
        }
      })
  }
}

export const createTenant = (data) => {
  return (dispatch) => {
    return http
      .post('/tenant', data)
      .then((res) => {
        dispatch(tenantFetched(res.data))
        //dispatch(shouldShowFarmSetupWizard())
        return 'TenantCreated'
      })
      .catch((error) => {
        if (error.response && error.response.status) {
          dispatch(shouldShowFarmSetupWizard())
        }
      })
  }
}

export const editTenant = (data) => {
  return (dispatch) => {
    return http
      .put('/tenant', data)
      .then((res) => {
        dispatch(tenantFetched(res.data))
        dispatch(closeModal())
        dispatch(setEditTenant(false))
        dispatch(
          addEvent(
            'Successfully updated your tenant details',
            'Edit tenant information',
          ),
        )
      })
      .catch(() => {
        dispatch(
          addEvent(
            'Unable to edit tenant at the moment',
            'Edit tenant failure',
          ),
        )
      })
  }
}

//Change the preferences object to tenant table from farm table

export const editTenantPreferences = (data) => {
  return (dispatch) => {
    return http
      .put(`/tenant/prefs`, data)
      .then((res) => {
        dispatch(tenantFetched(res.data))
        dispatch(
          addEvent('Successfully updated your preferences', 'Edit Preferences'),
        )
      })
      .catch(() => {
        dispatch(
          addEvent(
            'Unable to update Preferences at the moment',
            'Edit Preferences failure',
          ),
        )
      })
  }
}

export const fetchOptions = () => {
  return (dispatch) => {
    return http.get('/opts').then((res) => {
      dispatch(optionsFetched(res.data))
    })
  }
}

export const inviteUser = (data) => {
  return (dispatch) => {
    return http
      .post('/tenant/invite', data)
      .then(() => {
        dispatch(
          addEvent(
            'Success! An invitation email has been sent to the user.',
            'Invite User information',
          ),
        )
        dispatch(closeModal())
        dispatch(fetchInvitedUsers())
        dispatch(fetchAccessibleAccounts())
      })
      .catch(() => {
        dispatch(
          addEvent(
            'Unable to Invite user at the moment',
            'Invite User failure',
          ),
        )
      })
  }
}

export const fetchAccessibleAccounts = () => {
  return (dispatch) => {
    return http.get('/tenant/accessible-accounts').then((res) => {
      dispatch(accessibleAccountsFetched(res.data))
    })
  }
}

export const fetchInvitedUsers = () => {
  return (dispatch) => {
    return http.get('/tenant/invited-users').then((res) => {
      dispatch(invitedUsersFetched(res.data))
    })
  }
}

export const removeAccessibleAccount = (tenantId, userId) => {
  return (dispatch) => {
    return http
      .post('/tenant/invited-users', {
        tenantId,
        userId,
      })
      .then(() => {
        dispatch(fetchInvitedUsers())
        dispatch(fetchAccessibleAccounts())
      })
  }
}

export const setLocalTenantObj = (data) => {
  return (dispatch) => {
    dispatch(setLocalTenantDetails(data))
  }
}
