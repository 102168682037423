import { useAuth0 } from '@auth0/auth0-react'
import {
  setAuth,
  setHasTokenWithPermissionsRetries,
  setPermissions,
  setCustomClaims,
} from 'actions/account'
import * as c from 'common/c'
import { getPartner } from 'common/misc'
import { useTranslation } from 'react-i18next'
import RegistrationWizard from 'components/new-wizard'
import { jwtDecode } from 'jwt-decode'
import { get, pick } from 'lodash-es'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { hasToken, setToken } from '../../actions/account'
import { Loading } from '../../components/loading/loading'
import AppInternal from './app-internal'
import { getIntegrationToken } from 'api/integration'
import AccountImproperConfig from 'components/account-improper-config'

export default function App() {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const auth0 = useAuth0()

  const permissions = useSelector((state) => state.account.permissions)
  const hasTokenWithPermissionsRetries = useSelector(
    (state) => state.account.hasTokenWithPermissionsRetries,
  )
  const auth = useSelector((state) => get(state, 'account.auth', {}))
  const appState = useSelector((state) => get(state, 'app.state'))
  const authToken = useSelector((state) => get(state, 'account.token'))

  const {
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
  } = auth0

  const integrationLogin = localStorage.getItem('integrationLoginAttempt')

  const { error } = auth

  const initializeAuthState = (retries) => {
    // First attempt will read the token from cache, subsequent attempt will request a new token to work around initial role assignment, see:
    // https://community.auth0.com/t/after-applying-a-role-to-a-user-using-a-rule-at-signup-first-login-the-permissions-are-not-in-the-users-access-token/32884
    getAccessTokenSilently({ ignoreCache: retries > 0 }).then((token) => {
      const decodedToken = jwtDecode(token)

      if (
        Array.isArray(decodedToken?.permissions) &&
        decodedToken.permissions.length > 0
      ) {
        dispatch(setToken(token))
        dispatch(hasToken())
        dispatch(setPermissions([...decodedToken.permissions]))
        //Check if the token, on Impersonation has custom claims set
        if (
          Object.hasOwn(decodedToken, c.FT_ADMIN_CLAIM) &&
          Object.hasOwn(decodedToken, c.FT_PARTNER_CLAIM)
        ) {
          dispatch(
            setCustomClaims(
              pick(decodedToken, [c.FT_ADMIN_CLAIM, c.FT_PARTNER_CLAIM]),
            ),
          )
        }
        dispatch(setHasTokenWithPermissionsRetries(0))
      }

      dispatch(setHasTokenWithPermissionsRetries(retries + 1))
    })
  }

  useEffect(() => {
    if (
      isAuthenticated &&
      hasTokenWithPermissionsRetries < 5 &&
      permissions.length < 1
    ) {
      initializeAuthState(hasTokenWithPermissionsRetries)
    }
  }, [isAuthenticated, hasTokenWithPermissionsRetries, permissions])

  useEffect(() => {
    dispatch(setAuth(JSON.parse(JSON.stringify(auth0, null, 2))))
  }, [auth0])

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      if (integrationLogin) {
        el = <Loading fullscreen />
        getIntegrationToken(JSON.parse(integrationLogin), loginWithRedirect)
      } else {
        loginWithRedirect({ ui_locales: navigator.language })
      }
    }
  }, [isAuthenticated, isLoading, integrationLogin])

  let el = null

  if (
    isLoading ||
    (permissions.length < 1 && hasTokenWithPermissionsRetries >= 5) ||
    permissions.length < 1
  ) {
    el = <Loading fullscreen />
  }

  // This checks for improperly configured accounts
  if (
    !isLoading &&
    permissions.length < 1 &&
    hasTokenWithPermissionsRetries >= 5 &&
    authToken === undefined
  ) {
    el = <AccountImproperConfig />
  }

  if (error) {
    if (error.error && error.error === 'invalid_grant') {
      localStorage.removeItem(
        `@@auth0spajs@@::${AUTH_CID}::https://api.farmtrx.com::openid profile email admin offline_access`,
      )
      window.location.href = '/'
    } else if (integrationLogin) {
      el = <Loading fullscreen />
    } else if (error?.message) {
      el = <div>{t('oops', { message: error.message })}</div>
    }
  } else {
    if (isAuthenticated === true && permissions.length > 0) {
      if (
        appState === c.APP_STATE.FARM_SETUP_WIZARD ||
        appState === c.APP_STATE.USER_CREATE ||
        appState === c.APP_STATE.ACCOUNT_CREATE
      ) {
        el = <RegistrationWizard partner={getPartner()} />
      } else if (appState === c.APP_STATE.SHOW_DASHBOARD) {
        el = <AppInternal />
      } else {
        return <AppInternal />
      }
    }
  }
  return el
}
