import { Box, Button, Checkbox } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import { Loading } from 'components/loading/loading'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import { createMultipleFields, saveMultipleFeatures } from 'api/field'
import { v4 as uuidv4 } from 'uuid'
import Autocomplete from '@mui/material/Autocomplete'
import ItemSwitch from 'components/layers-group/item-switch'
import { ErrorOutlineOutlined as ErrorIcon } from '@mui/icons-material'

import { cloneDeep, isEmpty, some } from 'lodash-es'
import { getClientFarm, getTranslatedName } from 'common/misc'
import { useCallback, useEffect, useState } from 'react'
import FileUploadInput from 'components/lands/upload-boundaries/fileUploadInput'
import { createClient } from 'api/client'
import { createFarm } from 'api/farm'
import { fieldsImported as resetFieldsImported } from 'actions/field'

const useStyles = makeStyles(() => ({
  borderedBox: {
    border: '1px solid #003057',
    borderRadius: '2px',
  },
  resizer: {
    /* Displayed at the right side of column */
    position: 'absolute',
    top: 0,
    right: 0,
    width: '5px',
    cursor: 'col-resize',
    userSelect: 'none',
  },
  stickyHeaderBackground: {
    backgroundColor: '#ddd',
    minWidth: '70px',
  },
  stickyHeader: {
    position: 'sticky',
    zIndex: 10,
  },
  cell: { minWidth: '70px' },
  errorMessage: {
    borderLeft: '8px solid #ff00006b !important',
    background: '#ff00001a !important',
    color: 'red !important',
    fontWeight: 'bold !important',
  },
}))

const StickyTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#ddd',
    left: 0,
    position: 'sticky',
    zIndex: theme.zIndex.appBar + 2,
    minWidth: '50px',
  },
  body: {
    backgroundColor: '#ddd',
    minWidth: '70px',
    left: 0,
    position: 'sticky',
    zIndex: theme.zIndex.appBar + 1,
  },
}))(TableCell)

export default function FieldImportGrid(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    onImportField,
    defaultClient,
    defaultFarm,
    clients,
    isTransferringData,
    setTransferringData,
  } = props
  const defaultColumnName = { pty: '', item: '' }
  const defaultClientProperty = { pty: 'farmtrx-client', key: 'farmtrx-client' }
  const defaultFarmProperty = { pty: 'farmtrx-farm', key: 'farmtrx-farm' }
  const { t } = useTranslation()

  const fieldsImported = useSelector((state) => state.field['fieldsImported'])
  //TODO: this is just to make field import work in dev, need to actually update
  //this view to allow user to say which column to use for field, and find matching
  //fields if they exist.
  // const selectedFarm = useSelector((state) => state.farm.singleton)
  const [availableColumns, setAvailableColumns] = useState([])
  const [fieldProperties, setFieldProperties] = useState([])
  const [clientProperties, setClientProperties] = useState([
    defaultClientProperty,
  ])
  const [allSelected, setAllSelected] = useState(false)
  const [farmProperties, setFarmProperties] = useState([defaultFarmProperty])
  //Field name Autocomplete
  const [fieldNameProperty, setFieldNameProperty] = useState(defaultColumnName)
  //Farm Autocomplete
  const [farmNameProperty, setFarmNameProperty] = useState(defaultFarmProperty)
  //Client Autocomplete
  const [clientNameProperty, setClientNameProperty] = useState(
    defaultClientProperty,
  )
  const [rows, setRows] = useState([])
  const [errors, setErrors] = useState([])

  const [selectedFields, setSelectedFields] = useState([])
  const [selected, setSelected] = React.useState([])

  const [selectedClient, setSelectedClient] = useState(defaultClient)
  const [selectedFarm, setSelectedFarm] = useState(defaultFarm)

  const cffEnabled = useSelector(
    (state) => state.account.singleton.prefs?.cff?.enabled ?? false,
  )

  function getTranslatedMessage(message) {
    const lowercaseMessage = message.toLowerCase()
    if (lowercaseMessage == 'wrong projection. only wGS84 files are allowed.') {
      return t('error_projection')
    } else if (lowercaseMessage == 'shx or dbf file is missing.') {
      return t('error_missing_file')
    }
    return message
  }

  const geojsonObject = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: null,
        id: 1,
        geometry: {
          type: 'MultiPolygon',
        },
      },
    ],
  }
  const fieldImportTable = useRef()

  useEffect(() => {
    if (cffEnabled && clients.length && defaultClient) {
      const { client, farms } = getClientFarm(
        cffEnabled,
        clients,
        defaultClient,
      )
      if (client) {
        setSelectedClient(client)
      } else {
        setSelectedClient(clients[0])
      }

      if (
        defaultFarm &&
        farms &&
        farms.find((f) => {
          f.id === defaultFarm.id
        })
      ) {
        setSelectedFarm(defaultFarm)
      } else if (farms && farms.length > 0) {
        setSelectedFarm(farms[0])
      } else {
        setSelectedFarm(clients[0].farms[0])
      }
    }
  }, [clients, cffEnabled, defaultClient, defaultFarm])
  useEffect(() => {
    setTransferringData(false)
  }, [fieldsImported])

  useEffect(() => {
    if (fieldsImported && fieldsImported.properties) {
      let _clientProperties = [defaultClientProperty]
      let _farmProperties = [defaultFarmProperty]
      let _fieldProperties = [defaultColumnName]
      fieldsImported.properties.map((item) => {
        _fieldProperties.push({ pty: item, key: item })
        _clientProperties.push({ pty: item, key: item })
        _farmProperties.push({ pty: item, key: item })
      })
      setAvailableColumns(_fieldProperties)
      setClientProperties(_clientProperties)
      setFarmProperties(_farmProperties)
      setFieldProperties(_fieldProperties)
      //Both valid and invalid loop
      let final = []
      Object.entries(fieldsImported && fieldsImported.fields).map((item) => {
        item[1].map((innerItem) => {
          if (innerItem.geom_type === 'POLYGON') {
            innerItem.geomType = 'polygon'
          } else if (innerItem.geom_type === 'MULTIPOLYGON') {
            innerItem.geomType = 'multipolygon'
            innerItem.msg = t('multiple_fields')
          }
          innerItem.uniqueProperty = uuidv4()
          innerItem.farmTrxClient = selectedClient ?? clients[0]
          innerItem.clientNameProperty = defaultClientProperty.pty
          innerItem.farmNameProperty = defaultFarmProperty.pty
          innerItem.farmTrxFarm =
            selectedFarm && !isEmpty(selectedFarm)
              ? selectedFarm
              : clients[0]?.farms[0]
          final = [...final, innerItem]
        })
      })
      setRows(final)
    }
    //End
  }, [fieldsImported])

  const handleInputChange = useCallback((e, row, newValue) => {
    let { name, value } = e.target
    const { uniqueProperty } = row
    const newRows = rows.map((row) => {
      if (row.uniqueProperty === uniqueProperty) {
        if (!name) {
          name = 'fieldNamePty'
          if (newValue && 'inputValue' in newValue) {
            alert('new added')
            value = { pty: newValue.inputValue, key: newValue.inputValue }
          } else {
            value = newValue
          }
        }
        let fields = []
        if (selectedFields && selectedFields.length) {
          fields = selectedFields.map((item) => {
            if (item.uniqueProperty === uniqueProperty)
              return update(item, {
                attributes: { $merge: { [name]: value } },
              })
            else return item
          })
        }
        setSelectedFields(fields)
        return update(row, { attributes: { $merge: { [name]: value } } })
      }
      return row
    })
    setRows(newRows)
  })

  const isSelected = (uniqueProperty) => selected.indexOf(uniqueProperty) !== -1

  const selectAllFields = (event) => {
    let allFieldsChecked = event.target.checked
    setAllSelected(allFieldsChecked)
    if (allFieldsChecked) {
      const validRows = rows.filter((item) => item.valid)
      const newSelected = validRows.map((n) => n?.uniqueProperty)
      setSelected(newSelected)
      setSelectedFields(validRows)
      return
    }
    setSelected([])
    setSelectedFields([])
  }

  const closePopup = useCallback(() => {
    dispatch(resetFieldsImported(undefined))
    setFieldProperties([])
    setRows([])
    setAllSelected(false)
  })

  const selectField = useCallback((e, row) => {
    let checked = e.currentTarget.checked
    let newSelected = []
    if ('fieldNamePty' in row && checked) {
      newSelected = [...selected, row?.uniqueProperty]
      setSelected(newSelected)
      const newSelectedFields = [...selectedFields, row]
      const validRows = rows.filter((item) => item.valid)
      setAllSelected(newSelectedFields.length === validRows.length)
      setSelectedFields(newSelectedFields)
    } else {
      if (!checked) {
        setAllSelected(false)
        selectedFields.map((item) => {
          if (row.uniqueProperty === item?.uniqueProperty) {
            setSelected(selected.filter((id) => item?.uniqueProperty !== id))

            setSelectedFields(
              selectedFields.filter(
                (s) => row?.uniqueProperty != s?.uniqueProperty,
              ),
            )
          }
        })
      }
    }
  })

  const setClientName = useCallback(
    (event, newValue, reason) => {
      const newFarms =
        newValue.pty === defaultClientProperty.pty ? [defaultFarmProperty] : []
      const newFields = [defaultColumnName]
      let _farmNameProperty = farmNameProperty
      let resetFarmProperty = false
      for (let i = 0; i < availableColumns.length; i++) {
        if (
          newFarms.length === 0 &&
          farmNameProperty.pty === defaultFarmProperty.pty
        ) {
          _farmNameProperty = availableColumns[i]
        }
        if (
          availableColumns[i].pty !== fieldNameProperty.pty &&
          newValue.pty !== availableColumns[i].pty
        ) {
          newFarms.push(availableColumns[i])
        }
        if (
          availableColumns[i].pty !== _farmNameProperty.pty &&
          newValue.pty !== availableColumns[i].pty
        ) {
          newFields.push(availableColumns[i])
        }
      }
      if (_farmNameProperty.pty !== farmNameProperty.pty) {
        resetFarmProperty = true
        setFarmNameProperty(_farmNameProperty)
      }

      setFarmProperties(newFarms)
      setFieldProperties(newFields)
      let value = newValue['pty']
      rows.map(function (item) {
        if (value != '' && item.valid) {
          item.clientNameProperty = value
          if (resetFarmProperty) {
            item['farmNameProperty'] = _farmNameProperty.pty
          }
        } else delete item['clientNameProperty']
      })
      setRows([...rows])
      if (reason === 'selectOption') {
        setClientNameProperty(newValue)
      }
    },
    [rows, availableColumns, farmNameProperty, fieldNameProperty],
  )

  const onFarmChange = useCallback(
    (event, newValue, reason) => {
      const newClients = [defaultClientProperty]
      const newFields = [defaultColumnName]
      for (let i = 0; i < availableColumns.length; i++) {
        if (
          availableColumns[i].pty !== fieldNameProperty.pty &&
          newValue.pty !== availableColumns[i].pty
        ) {
          newClients.push(availableColumns[i])
        }
        if (
          availableColumns[i].pty !== clientNameProperty.pty &&
          newValue.pty !== availableColumns[i].pty
        ) {
          newFields.push(availableColumns[i])
        }
      }

      setClientProperties(newClients)
      setFieldProperties(newFields)
      if (reason === 'clear') {
        rows.forEach(function (v) {
          delete v.farmNameProperty
        })
      } else {
        let value = newValue['pty']
        rows.map(function (item) {
          if (value != '' && item.valid) item.farmNameProperty = value
          else delete item['farmNameProperty']
        })
      }
      setRows([...rows])
      if (reason === 'selectOption') {
        setFarmNameProperty(newValue)
      }
    },
    [rows, availableColumns, fieldNameProperty, clientNameProperty],
  )

  const setFieldName = useCallback(
    (event, newValue, reason) => {
      const newClients = [defaultClientProperty]
      const newFarms =
        clientNameProperty.pty === defaultClientProperty.pty
          ? [defaultFarmProperty]
          : []
      for (let i = 0; i < availableColumns.length; i++) {
        if (
          availableColumns[i].pty !== farmNameProperty.pty &&
          newValue.pty !== availableColumns[i].pty
        ) {
          newClients.push(availableColumns[i])
        }
        if (
          availableColumns[i].pty !== clientNameProperty.pty &&
          newValue.pty !== availableColumns[i].pty
        ) {
          newFarms.push(availableColumns[i])
        }
      }

      setClientProperties(newClients)
      setFarmProperties(newFarms)
      if (reason === 'clear') {
        rows.forEach(function (v) {
          delete v.fieldNamePty
        })
      } else {
        let value = newValue['pty']
        rows.map(function (item) {
          if (value != '' && item.valid) item.fieldNamePty = value
          else delete item['fieldNamePty']
        })
      }
      setRows([...rows])
      if (reason === 'selectOption') {
        setFieldNameProperty(newValue)
      }
    },
    [rows, availableColumns, farmNameProperty, clientNameProperty],
  )

  const onSetAllClients = useCallback(
    (event, newValue) => {
      if (newValue.id !== selectedClient?.id) {
        let newFarm =
          newValue?.farms.length > 0
            ? newValue.farms[0]
            : {
                id: -1,
                name: newValue.name,
              }
        if (newValue.farms.length === 0) {
          newValue.farms.push(newFarm)
        }

        setSelectedClient(newValue)
        setSelectedFarm(newFarm)
        rows.map(function (item) {
          if (item.valid) {
            item.farmTrxClient = newValue
            item.farmTrxFarm = newFarm
          }
        })
        setRows([...rows])
      }
    },
    [rows, selectedClient],
  )

  const onSetAllFarms = useCallback(
    (event, newValue) => {
      setSelectedFarm(newValue)
      rows.map(function (item) {
        if (item.valid) item.farmTrxFarm = newValue
      })
      setRows([...rows])
    },
    [rows],
  )

  useEffect(() => {
    const duplicateNames = []
    const _errors = []
    const nameCount = {}
    selectedFields.map((item) => {
      let fieldNamePty = item.fieldNamePty
      if (
        item.attributes[fieldNamePty] === '' ||
        !item.attributes[fieldNamePty]
      ) {
        _errors.push(t('enter_selected_field_name'))
        //return
      } else {
        let name = item.attributes[fieldNamePty]

        const count = nameCount[name] ? nameCount[name] : 0
        nameCount[name] = count + 1
        if (nameCount[name] === 2) {
          duplicateNames.push(name)
        }
      }
    })
    if (duplicateNames.length) {
      let names = duplicateNames[0]
      for (let i = 1; i < duplicateNames.length; i++) {
        names += `, ${duplicateNames[i]}`
      }
      _errors.push(t('multiple_fields_same_name', { fields: names }))
    }
    setErrors(_errors)
  }, [selectedFields, fieldNameProperty])

  const importSelectedFields = useCallback(() => {
    setTransferringData(true)
    let newClients = []
    let newFarms = []
    let fieldNames = []
    for (let i = 0; i < selectedFields.length; i++) {
      const item = selectedFields[i]
      let clientName =
        clientNameProperty.pty !== defaultClientProperty.pty
          ? item.attributes[clientNameProperty.pty]
          : item.farmTrxClient.name
      let newFarmName =
        farmNameProperty.pty !== defaultFarmProperty.pty
          ? item.attributes[farmNameProperty.pty]
          : item.farmTrxFarm.name
      let fieldNamePty = item.fieldNamePty
      let fieldName = item.attributes[fieldNamePty]
      const existingClient = clients.find(
        (client) => client.name === clientName,
      )
      if (existingClient) {
        const existingFarm = existingClient.farms.find(
          (item) => item.name === newFarmName,
        )
        if (existingFarm) {
          const fieldRecords = createFieldEntry(
            {
              name: fieldName,
              geom_type: item.geom_type,
              objects: item.objects,
              farmId: existingFarm.id,
            },
            existingFarm.id,
          )
          fieldNames = fieldNames.concat(fieldRecords)
        } else {
          const farmIndex = newFarms.findIndex(
            (farmToCreate) =>
              farmToCreate.name === newFarmName &&
              farmToCreate.clientName === clientName,
          )
          if (farmIndex === -1) {
            let newFarm = {
              name: newFarmName,
              clientName: clientName,
              fields: [
                {
                  name: fieldName,
                  geom_type: item.geom_type,
                  objects: item.objects,
                },
              ],
            }
            if (existingClient) {
              newFarm.clientId = existingClient.id
            }
            newFarms.push(newFarm)
          } else {
            newFarms[farmIndex].fields.push({
              name: fieldName,
              geom_type: item.geom_type,
              objects: item.objects,
            })
          }
        }
      } else {
        const clientIndex = newClients.findIndex(
          (clientToCreate) => clientToCreate.name === clientName,
        )
        if (clientIndex === -1) {
          newClients.push({
            name: clientName,
            farms: [
              {
                name: newFarmName,
                fields: [
                  {
                    name: fieldName,
                    geom_type: item.geom_type,
                    objects: item.objects,
                  },
                ],
              },
            ],
          })
        }
        const farmIndex = newFarms.findIndex(
          (farmToCreate) =>
            farmToCreate.name === newFarmName &&
            farmToCreate.clientName === clientName,
        )

        if (farmIndex === -1) {
          newFarms.push({
            name: newFarmName,
            clientName: clientName,
            fields: [
              {
                name: fieldName,
                geom_type: item.geom_type,
                objects: item.objects,
              },
            ],
          })
        } else {
          newFarms[farmIndex].fields.push({
            name: fieldName,
            geom_type: item.geom_type,
            objects: item.objects,
          })
        }
      }
    }
    if (newClients.length > 0) {
      Promise.all(
        newClients.map((client) =>
          dispatch(
            createClient({
              name: client.name,
              streetAddress: '',
              postalCode: '',
              email: '',
            }),
          ),
        ),
      ).then((results) => {
        for (let i = 0; i < results.length; i++) {
          if (results[i].message === 'Client created') {
            const clientName = results[i].data.name
            const clientId = results[i].data.id
            for (let farm in newFarms) {
              if (newFarms[farm].clientName === clientName) {
                newFarms[farm].clientId = clientId
              }
            }
          }
        }
        Promise.all(
          newFarms.map((farm) =>
            dispatch(
              createFarm({
                name: farm.name,
                streetAddress: '',
                postalCode: '',
                clientId: farm.clientId,
              }),
            ),
          ),
        ).then((farmRes) => {
          for (let i = 0; i < farmRes.length; i++) {
            const farmId = farmRes[i].data.id
            const clientId = farmRes[i].data.clientId
            const name = farmRes[i].data.name
            const farm = newFarms.find(
              (item) => item.clientId === clientId && item.name === name,
            )
            if (farm) {
              for (let j = 0; j < farm.fields.length; j++) {
                const fieldRecords = createFieldEntry(farm.fields[j], farmId)
                fieldNames = fieldNames.concat(fieldRecords)
              }
            }
          }
          if (fieldNames.length) {
            dispatch(createMultipleFields(fieldNames)).then((fieldsRes) => {
              importBoundaries(fieldsRes.data, fieldNames)
            })
          }
        })
        //
      })
    } else if (newFarms.length > 0) {
      Promise.all(
        newFarms.map((farm) =>
          dispatch(
            createFarm({
              name: farm.name,
              streetAddress: '',
              postalCode: '',
              clientId: farm.clientId,
            }),
          ),
        ),
      ).then((farmRes) => {
        for (let i = 0; i < farmRes.length; i++) {
          const farmId = farmRes[i].data.id
          const clientId = farmRes[i].data.clientId
          const name = farmRes[i].data.name
          const farm = newFarms.find(
            (item) => item.clientId === clientId && item.name === name,
          )
          if (farm) {
            for (let j = 0; j < farm.fields.length; j++) {
              const fieldRecords = createFieldEntry(farm.fields[j], farmId)
              fieldNames = fieldNames.concat(fieldRecords)
            }
          }
        }
        if (fieldNames.length) {
          dispatch(createMultipleFields(fieldNames)).then((fieldsRes) => {
            importBoundaries(fieldsRes.data, fieldNames)
          })
        }
      })
    } else {
      if (fieldNames.length) {
        dispatch(createMultipleFields(fieldNames)).then((fieldsRes) => {
          importBoundaries(fieldsRes.data)
        })
      }
    }
  }, [selectedFields, clientNameProperty, farmNameProperty])

  const createFieldEntry = function (item, farmId) {
    let name = item.name
    let fieldNames = []

    if (item.geom_type === 'POLYGON') {
      fieldNames.push({
        name,
        farmId: parseInt(farmId),
      })
    } else if (item.geom_type === 'MULTIPOLYGON') {
      const objects = item.objects
      for (let i = 1; i <= objects; i++) {
        const multiName = `${name} ${i}`
        fieldNames.push({
          name: multiName,
          farmId: parseInt(farmId),
        })
      }
    }
    return fieldNames
  }

  const importBoundaries = function (fieldCollection) {
    let finalPayload = []
    if (selectedFields.length) {
      for (let i = 0; i < selectedFields.length; i++) {
        const item = selectedFields[i]
        let fieldNamePty = item?.fieldNamePty
        let name = item?.attributes[fieldNamePty]
        let id

        let obj = cloneDeep(geojsonObject)
        let geoObj = JSON.parse(item.geo_object).coordinates
        if (item.geom_type === 'POLYGON') {
          id = fieldCollection.find((s) => s.name == name)?.id
          if (id) {
            obj.features[0].geometry.coordinates = [geoObj]

            finalPayload.push({ id, featuresObj: obj })
          }
        } else if (item.geom_type === 'MULTIPOLYGON') {
          let index = 1
          geoObj.map((polygon) => {
            const newItem = cloneDeep(geojsonObject)
            const newName = `${name} ${index}`
            id = fieldCollection.find((s) => s.name == newName)?.id
            if (id) {
              index++
              newItem.features[0].geometry.coordinates = [polygon]

              finalPayload.push({ id, featuresObj: newItem })
            }
          })
        }
      }
      setClientNameProperty(defaultClientProperty)
      setClientProperties([defaultClientProperty])
      setFarmNameProperty(defaultFarmProperty)
      setFarmProperties([defaultFarmProperty])
      setFieldNameProperty(defaultColumnName)
      setFieldProperties([defaultColumnName])
      dispatch(saveMultipleFeatures(finalPayload))
      dispatch(resetFieldsImported(undefined))
      setRows([])
      setAllSelected(false)
    }
  }

  useEffect(() => {
    const cols = fieldImportTable.current.querySelectorAll('th')

    // Loop over them
    cols.forEach((col) => {
      const resizer = document.createElement('div')
      resizer.classList.add(classes.resizer)

      // Set the height
      resizer.style.height = `${fieldImportTable.current.offsetHeight}px`

      // Add a resizer element to the column
      col.appendChild(resizer)

      // Will be implemented in the next section
      createResizableColumn(col, resizer)
    })
  }, [rows])
  //End

  const createResizableColumn = function (col, resizer) {
    // Track the current position of mouse
    let x = 0
    let w = 0

    const mouseDownHandler = function (e) {
      // Get the current mouse position
      x = e.clientX

      // Calculate the current width of column
      const styles = window.getComputedStyle(col)
      w = parseInt(styles.width, 10)

      // Attach listeners for document's events
      document.addEventListener('mousemove', mouseMoveHandler)
      document.addEventListener('mouseup', mouseUpHandler)

      //  resizer.classList.add(classes.resizer);
    }

    const mouseMoveHandler = function (e) {
      // Determine how far the mouse has been moved
      const dx = e.clientX - x

      // Update the width of column
      col.style.width = `${w + dx}px`
    }

    // When user releases the mouse, remove the existing event listeners
    const mouseUpHandler = function () {
      document.removeEventListener('mousemove', mouseMoveHandler)
      document.removeEventListener('mouseup', mouseUpHandler)
      //  resizer.classList.remove(classes.resizer);
    }

    resizer.addEventListener('mousedown', mouseDownHandler)
  }
  const handleFilesOnDrop = (event) => {
    event.preventDefault()
    setTransferringData(true)
    let fileData = event.dataTransfer.files
    onImportField(fileData)
  }

  const allowDrop = (event) => {
    event.preventDefault()
  }

  return (
    <>
      <Box width={1}>
        {errors.length
          ? errors.map((e) => (
              <ItemSwitch className={classes.errorMessage} title={e} key={e}>
                <ErrorIcon
                  size="small"
                  style={{
                    color: '#B2023B',
                    height: '0.7em',
                    verticalAlign: 'sub',
                  }}
                  titleAccess={t(e)}
                />
                {e}
              </ItemSwitch>
            ))
          : null}
        <Box mx={1} my={1} className={classes.borderedBox}>
          <Box display="flex" mx={1} my={1} alignItems="center">
            {fieldProperties && fieldProperties.length ? (
              <>
                {cffEnabled ? (
                  <>
                    <Box
                      flexGrow={1}
                      mr={1}
                      style={{ width: '20%', marginLeft: '5px' }}
                    >
                      <Autocomplete
                        isOptionEqualToValue={(option, value) => {
                          return option.pty === value.pty
                        }}
                        disableClearable
                        fullWidth
                        style={{ maxHeight: '40px' }}
                        sx={{
                          '& input': {
                            height: '1em',
                          },
                          '& label': {
                            lineHeight: '1.5em',
                            fontSize: '0.75em',
                          },
                        }}
                        id="client_column"
                        autoHighlight
                        size="small"
                        options={clientProperties}
                        selectOnFocus
                        getOptionLabel={(option) =>
                          getTranslatedName(option.pty) || ''
                        }
                        value={clientNameProperty}
                        onChange={(event, newValue, reason) => {
                          setClientName(event, newValue, reason)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('select_client_column')}
                            placeholder={t('clients')}
                            style={{ width: '100%' }}
                          />
                        )}
                      />
                    </Box>
                    <Box flexGrow={1} mr={1} style={{ width: '20%' }}>
                      <Autocomplete
                        disableClearable
                        isOptionEqualToValue={(option, value) => {
                          return option.pty === value.pty
                        }}
                        fullWidth
                        style={{ maxHeight: '40px', minWidth: '' }}
                        sx={{
                          '& input': {
                            height: '1em',
                          },
                          '& label': {
                            lineHeight: '1.5em',
                            fontSize: '0.75em',
                          },
                        }}
                        id="farm_column"
                        size="small"
                        autoHighlight
                        options={farmProperties}
                        getOptionLabel={(option) =>
                          getTranslatedName(option.pty) || ''
                        }
                        value={farmNameProperty}
                        selectOnFocus
                        onChange={(event, newValue, reason) => {
                          onFarmChange(event, newValue, reason)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('select_farm_column')}
                            style={{ width: '100%' }}
                          />
                        )}
                        renderTags={(value) => {
                          const numTags = value.length
                          return (
                            <div data-variant="body2">
                              {value
                                .slice(0, 1)
                                .map((option) => option.name)
                                .join(', ')}

                              {numTags > 1 && ` +${numTags - 1} more`}
                            </div>
                          )
                        }}
                      />
                    </Box>
                  </>
                ) : null}
                <Box flexGrow={1} style={{ width: '20%' }}>
                  <Autocomplete
                    disableClearable
                    isOptionEqualToValue={(option, value) => {
                      return option.pty === value.pty
                    }}
                    fullWidth
                    value={fieldNameProperty}
                    style={{ maxHeight: '40px' }}
                    sx={{
                      '& input': {
                        height: '1em',
                      },
                      '& label': {
                        lineHeight: '1.5em',
                        fontSize: '0.75em',
                      },
                    }}
                    id="fieldName"
                    autoHighlight
                    size="small"
                    options={fieldProperties}
                    selectOnFocus
                    getOptionLabel={(option) => option.pty || ''}
                    onChange={(event, newValue, reason) => {
                      setFieldName(event, newValue, reason)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('select_field_name')}
                        placeholder={t('select_field_name')}
                        style={{ width: '100%' }}
                      />
                    )}
                  />
                </Box>
              </>
            ) : null}
          </Box>
          <div
            data-component={Paper}
            style={{ maxHeight: '49vh', overflow: 'auto' }}
          >
            <Table
              aria-label="field-import table"
              size="small"
              stickyHeader
              ref={fieldImportTable}
            >
              <TableHead>
                <TableRow className={classes.stickyHeader}>
                  <StickyTableCell padding="checkbox" styles={{ zIndex: 1 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.stickyHeaderBackground}>
                            <Checkbox
                              checked={allSelected}
                              onChange={selectAllFields}
                              disabled={
                                cffEnabled
                                  ? !selectedFarm?.id ||
                                    !rows.filter((row) => 'fieldNamePty' in row)
                                      .length > 0
                                  : !rows.filter((row) => 'fieldNamePty' in row)
                                      .length > 0
                              }
                            />
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.stickyHeaderBackground}
                          >
                            {t('geo_type')}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.stickyHeaderBackground}
                          >
                            {t('valid')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </StickyTableCell>

                  <TableCell align="left">{t('note')}</TableCell>

                  {fieldsImported &&
                  fieldsImported.properties &&
                  fieldsImported.properties.length ? (
                    fieldsImported.properties.map((item) => (
                      <TableCell
                        align="left"
                        key={item}
                        width="10%"
                        component="th"
                        scope="row"
                      >
                        {item}
                      </TableCell>
                    ))
                  ) : (
                    <>
                      <TableCell align="left">{t('field_name')}</TableCell>
                    </>
                  )}
                  {cffEnabled && fieldsImported ? (
                    <>
                      <TableCell align="left" width="10%">
                        <Autocomplete
                          disableClearable
                          disabled={
                            clientNameProperty.pty !== defaultClientProperty.pty
                          }
                          value={selectedClient}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          style={{ maxHeight: '40px', minWidth: '120px' }}
                          sx={{
                            '& input': {
                              height: '1em',
                            },
                            '& label': {
                              lineHeight: '1.5em',
                              fontSize: '0.75em',
                            },
                          }}
                          id={`set-all-farmtrx-clients`}
                          autoHighlight
                          size="small"
                          options={clients}
                          selectOnFocus
                          getOptionLabel={(option) => option.name || ''}
                          onChange={(event, newValue, reason) => {
                            onSetAllClients(event, newValue, reason)
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={t('select_client')}
                              label={t('farmtrx-client')}
                              style={{ width: '100%' }}
                            />
                          )}
                        />
                      </TableCell>
                      {selectedClient.farms ? (
                        <TableCell align="left" width="10%">
                          <Autocomplete
                            options={
                              selectedClient?.farms || [{ id: 0, name: '' }]
                            }
                            disabled={
                              farmNameProperty.pty !== defaultFarmProperty.pty
                            }
                            value={selectedFarm}
                            style={{ maxHeight: '40px', minWidth: '120px' }}
                            sx={{
                              '& input': {
                                height: '1em',
                              },
                              '& label': {
                                lineHeight: '1.5em',
                                fontSize: '0.75em',
                              },
                            }}
                            id={`set-all-farmtrx-farms`}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            autoHighlight
                            size="small"
                            selectOnFocus
                            getOptionLabel={(option) => option.name || ''}
                            onChange={(event, newValue, reason) => {
                              onSetAllFarms(event, newValue, reason)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={t('select_farm')}
                                label={t('farmtrx-farm')}
                                style={{ width: '100%' }}
                              />
                            )}
                          />
                        </TableCell>
                      ) : null}
                    </>
                  ) : null}
                </TableRow>
              </TableHead>
              {rows.length && !isTransferringData ? (
                <TableBody>
                  {rows.map((row) => {
                    const isItemSelected = isSelected(row?.uniqueProperty)
                    const translatedMessage = getTranslatedMessage(row?.msg)
                    return (
                      <TableRow key={row?.uniqueProperty}>
                        <StickyTableCell padding="checkbox">
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell className={classes.cell}>
                                  <Checkbox
                                    disabled={
                                      cffEnabled
                                        ? !row.fieldNamePty ||
                                          !row.valid ||
                                          (farmNameProperty.pty ===
                                            defaultFarmProperty &&
                                            !selectedFarm?.id)
                                        : !row.fieldNamePty || !row.valid
                                    }
                                    checked={isItemSelected}
                                    onChange={(e) => selectField(e, row)}
                                  />
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className={classes.cell}
                                >
                                  {row.geomType}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className={classes.cell}
                                >
                                  {row.valid ? t('yes') : t('no')}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </StickyTableCell>

                        <TableCell align="left">{translatedMessage}</TableCell>

                        {fieldsImported && fieldsImported.properties.length ? (
                          fieldsImported.properties.map((item) => {
                            return (
                              <TableCell
                                align="left"
                                key={row?.uniqueProperty + item}
                                width="10%"
                                component="th"
                                scope="row"
                                className={classes.cell}
                              >
                                <TextField
                                  type="text"
                                  name={item}
                                  value={row.attributes?.[item]}
                                  title={row.attributes?.[item]}
                                  disabled={
                                    row.valid
                                      ? !(
                                          row.fieldNamePty == item ||
                                          row?.farmNameProperty == item ||
                                          row?.clientNameProperty == item
                                        )
                                      : true
                                  }
                                  onChange={(e) => handleInputChange(e, row)}
                                  inputProps={{ size: 10 }}
                                />
                              </TableCell>
                            )
                          })
                        ) : (
                          <>
                            <TableCell key={'noDataKey'} align="left">
                              {t('field_name')}
                            </TableCell>
                          </>
                        )}
                        {cffEnabled ? (
                          <>
                            <TableCell align="left" width="10%">
                              <Autocomplete
                                disableClearable
                                disabled={
                                  clientNameProperty.pty !==
                                  defaultClientProperty.pty
                                }
                                value={row.farmTrxClient}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                style={{ maxHeight: '40px', minWidth: '100px' }}
                                sx={{
                                  '& input': {
                                    height: '1em',
                                  },
                                  '& label': {
                                    lineHeight: '1.5em',
                                    fontSize: '0.75em',
                                  },
                                }}
                                id={`${row?.uniqueProperty}_clientName`}
                                autoHighlight
                                size="small"
                                options={clients}
                                selectOnFocus
                                getOptionLabel={(option) => option.name || ''}
                                onChange={(event, newValue) => {
                                  handleInputChange(
                                    { target: { name: 'farmTrxClient' } },
                                    row,
                                    newValue,
                                  )
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder={t('select_client')}
                                    style={{ width: '100%' }}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell align="left" width="10%">
                              <Autocomplete
                                disableClearable
                                options={row.farmTrxClient.farms ?? null}
                                disabled={
                                  farmNameProperty.pty !==
                                  defaultFarmProperty.pty
                                }
                                value={row.farmTrxFarm}
                                style={{ maxHeight: '40px', minWidth: '100px' }}
                                sx={{
                                  '& input': {
                                    height: '1em',
                                  },
                                  '& label': {
                                    lineHeight: '1.5em',
                                    fontSize: '0.75em',
                                  },
                                }}
                                id={`${row?.uniqueProperty}_farmName`}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                autoHighlight
                                size="small"
                                selectOnFocus
                                getOptionLabel={(option) => option.name || ''}
                                onChange={(event, newValue) => {
                                  handleInputChange(
                                    { target: { name: 'farmTrxFarm' } },
                                    row,
                                    newValue,
                                  )
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder={t('select_farm')}
                                    style={{ width: '100%' }}
                                  />
                                )}
                              />
                            </TableCell>
                          </>
                        ) : null}
                      </TableRow>
                    )
                  })}
                </TableBody>
              ) : null}
            </Table>
            {isTransferringData ? (
              <Box
                width={1}
                height="45vh"
                justifyContent="center"
                display={'flex'}
                alignItems={'center'}
                flexDirection={'column'}
                className={classes.fileImportComponent}
              >
                <Loading />
              </Box>
            ) : rows.length ? null : (
              <Box
                width={1}
                height="45vh"
                justifyContent="center"
                display={'flex'}
                alignItems={'center'}
                flexDirection={'column'}
                className={classes.fileImportComponent}
                onDrop={handleFilesOnDrop}
                onDragOver={allowDrop}
              >
                {t('drag_drop_files')}
                <FileUploadInput
                  mt="2"
                  onImport={(fileList) => {
                    setTransferringData(true)
                    onImportField(fileList)
                  }}
                />
              </Box>
            )}
          </div>

          <Box mx={1} my={1} display="flex" justifyContent="flex-end">
            <Button
              mx={1}
              my={1}
              variant="contained"
              color="primary"
              disabled={
                cffEnabled
                  ? selectedFields.length === 0 ||
                    (farmNameProperty.pty === defaultFarmProperty &&
                      !selectedFarm?.id) ||
                    !some(rows, 'fieldNamePty') ||
                    errors.length > 0
                  : !selectedFields.length ||
                    !some(rows, 'fieldNamePty') ||
                    errors.length > 0
              }
              onClick={importSelectedFields}
            >
              {t('import')}
            </Button>
            <Button color="secondary" onClick={closePopup}>
              {t('cancel')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}
